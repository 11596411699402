export {isServer} from '@invitae/nucleobase'

export type CountryCode = 'de-CH' | 'en-AU' | 'en-US'

/**
 * Convert a given locale from Contentful kind (`en-US`) to an ICU kind (`en_US`).
 */
export const normalizeLocale = (locale: string) => locale.replace(/-/g, '_')

/**
 * Add a schema to a url with an ambiguous schema (`//`) (default is `https`).
 *
 * ```
 * ensureSchema('//cdn.invitae.com/i/123') === 'https://cdn.invitae.com/i/123'
 * ```
 */
export const ensureSchema = (url: string, schema = 'https') => {
  if (url) return url.startsWith('//') ? `${schema}:${url}` : url
  return ''
}

/**
 * Construct a URL query string from a given object.
 */
export const queryStringFrom = (params: Record<string, string>) =>
  Object.entries(params)
    .map(parts => parts.map(encodeURIComponent).join('='))
    .join('&')

/**
 * Add a query parameter to a given URL to make Contentful return the image as a JPG.
 */
export const ensureCorrectPreviewImageUrl = (url: string) => `${url}?${queryStringFrom({fm: 'jpg'})}`
