import * as React from 'react'

import PanelModal from 'components/PanelGeneDetail/PanelModal'
import {parseHtmlTextile} from 'utils/utils'

interface IAssociatedDisordersModalProps {
  open: boolean
  onClose: () => void
  associatedDisorders: string
}

const AssociatedDisordersModal = ({open, onClose, associatedDisorders}: IAssociatedDisordersModalProps) => {
  return (
    <PanelModal onClose={onClose} open={open} title="Associated disorders">
      <div data-testid="associated-disorders-content">{parseHtmlTextile(associatedDisorders)}</div>
    </PanelModal>
  )
}

export default AssociatedDisordersModal
