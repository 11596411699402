import * as React from 'react'
import classNames from 'classnames'

import {useAnalyticsQueue} from '@invitae/nucleobase'
import {isServer} from '@invitae/nucleobase'
import {PanelDetailedPageDto} from '@invitae/stargate'

import {SponsoredTestingType} from 'api/types'
import Breadcrumbs from 'components/Breadcrumbs'
import AssaysSection from 'components/PanelGeneDetail/AssaysSection'
import OrderingInformationSection from 'components/PanelGeneDetail/OrderingInformationSection'
import {VIEW_PANEL_PAGE} from 'constants/analytics'
import {TDetailsPageType} from 'constants/pageType'
import {CUSTOM_ASSAY_INFO_TEST} from 'constants/specialCases'
import useHandlePanelCards, {PanelType} from 'hooks/useHandlePanelCards.hooks'
import getComponentFriendlyPanelDataFromAPIData from 'utils/handleSpecialCases/getComponentFriendlyPanelDataFromAPIData'

import ClinicalInformation from './ClinicalInformation'
import GeneralInfo from './GeneralInfo'
import OrderingTagsSection from './OrderingTagsSection'
import OtherTestsSection from './OtherTestsSection'
import PanelCustomizationSection from './PanelCustomizationSection'
import PanelDetailsContext from './PanelDetailContext'
import TestDescriptionSection from './TestDescriptionSection'

import styles from './PanelDetail.module.scss'

const PanelDetail = ({panel, partnerships}: {panel: PanelDetailedPageDto; partnerships: SponsoredTestingType}) => {
  const {logEvent} = useAnalyticsQueue()
  const {name} = panel
  const {primaryPanelData, optionalTestsData} = getComponentFriendlyPanelDataFromAPIData(panel, panel.optTests)
  const {isAddonProvided, ...rest} = useHandlePanelCards({optionalTestsData, primaryPanelData, type: PanelType.DETAIL})
  const isCustomAssayEnabled = React.useMemo(
    () => (panel.code ? panel.code.includes(CUSTOM_ASSAY_INFO_TEST) : false),
    [panel.code],
  )

  const contextValue = {
    isAddonProvided: isAddonProvided ?? false,
    isCustomAssayEnabled: isCustomAssayEnabled,
    optionalTestsData: optionalTestsData ?? [],
    ...rest,
  }

  const [isMobileOrderOpen, setIsMobileOrderOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isServer()) {
      logEvent({
        eventName: VIEW_PANEL_PAGE,
        eventProperties: {
          referrer: document.referrer,
        },
      })
    }
  }, [logEvent])

  return (
    <div className={classNames(styles.root, {[styles.rootHideForMobile]: isMobileOrderOpen})}>
      <div className="nv-container">
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs currentTitle={panel.name} />
        </div>
        <div className={styles.panelDataWrapper}>
          <PanelDetailsContext.Provider value={contextValue}>
            <div className={styles.panelDetailWrapper}>
              <GeneralInfo name={name ?? ''} />
              <span className={classNames(styles.border, styles.smallerBorderBottom)}></span>
              <OrderingTagsSection
                code={panel.code}
                partnerships={partnerships}
                turnaroundTime={panel.turnaroundTime ?? ''}
              />
              <TestDescriptionSection
                code={panel.code ?? ''}
                description={panel.description ?? ''}
                disordersTested={panel.disordersTested ?? ''}
              />
              <OrderingInformationSection
                alternateSpecimens={panel.alternateSpecimens ?? ''}
                kitInstructions={panel.kitInstructions ?? ''}
                pageType={TDetailsPageType.Panel}
                preferredSpecimen={panel.preferredSpecimen ?? ''}
                tags={panel.tags}
                turnaroundTime={panel.turnaroundTime ?? ''}
              />
              <ClinicalInformation
                clinicalDescription={panel.clinicalDescription ?? ''}
                testSensitivity={panel.testSensitivity ?? ''}
              />
              <AssaysSection
                assayInformation={panel.assayInformation ?? ''}
                geneTests={panel.geneTests ?? []}
                optTests={panel.optTests ?? []}
                pageType={TDetailsPageType.Panel}
                productType={panel.productType ?? ''}
                shouldShowAssayInfo={!isCustomAssayEnabled}
              />
            </div>
            <PanelCustomizationSection
              isMobileOrderOpen={isMobileOrderOpen}
              optionalTestsData={optionalTestsData || []}
              primaryPanelData={primaryPanelData}
              productType={panel.productType ?? ''}
              setIsMobileOrderOpen={() => setIsMobileOrderOpen(prev => !prev)}
            />
          </PanelDetailsContext.Provider>
        </div>
      </div>
      <OtherTestsSection alternativeTests={panel.alternativeTests ?? ''} />
    </div>
  )
}

export default PanelDetail
