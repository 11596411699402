// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable spellcheck/spell-checker */
import * as React from 'react'

import {PRODUCT_TYPE_DESCRIPTION} from 'constants/geneDetail'

import {assayInfoC9orf72} from './assayInfo'
import GeneDetailContext from './GeneDetailContext'

import styles from './GeneDetail.module.scss'

const SeeInvitaeTestsText = () => {
  const {scrollToOtherTests, productType, isCustomAssayEnabled} = React.useContext(GeneDetailContext)

  const productTypeDescription = React.useMemo(() => PRODUCT_TYPE_DESCRIPTION[productType], [productType])

  return (
    <div data-testid="see-invitae-tests">
      <p className={styles.productTypeText} data-testid="product-type-text">
        {productTypeDescription}
      </p>
      {isCustomAssayEnabled ? assayInfoC9orf72 : ''}
      <p>
        {productType === 'Carrier' ? '' : 'You can order this gene as a single gene or within a panel. '}
        See{' '}
        <button className="nv-plain-action" onClick={scrollToOtherTests}>
          Invitae tests
        </button>{' '}
        that include this gene.
      </p>
    </div>
  )
}

export default SeeInvitaeTestsText
