import * as React from 'react'

import PanelModal from 'components/PanelGeneDetail/PanelModal'
import {parseHtmlTextile} from 'utils/utils'

interface IDisordersTestedProps {
  open: boolean
  onClose: () => void
  disordersTested: string
}

const DisordersTestedModal = ({open, onClose, disordersTested}: IDisordersTestedProps) => {
  const disordersTestedHtml = React.useMemo(() => parseHtmlTextile(disordersTested), [disordersTested])

  return (
    <PanelModal onClose={onClose} open={open} title="Disorders tested">
      <div>{disordersTestedHtml}</div>
    </PanelModal>
  )
}

export default DisordersTestedModal
