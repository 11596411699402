import * as React from 'react'

import {useCountryLocationPrefix} from '@invitae/nucleobase'

import InfoTooltip from 'components/InfoTooltip/index'
import OrderContainerWrapper from 'components/PanelGeneDetail/OrderContainerWrapper'
import {PROVIDERS_BILLING_LINK} from 'constants/links'
import {TDetailsPageType} from 'constants/pageType'
import {PRODUCT_TYPE_RNA} from 'constants/specialCases'
import {IOptionalTestData, IPanelData} from 'hooks/types'
import {checkIsLocked} from 'utils/utils'

import PanelDetailsContext from '../PanelDetailContext'
import PanelItemCustomization from './PanelItemCustomization/index'

import styles from './PanelCustomizationSection.module.scss'

export interface IGeneCustomizationSectionProps {
  productType: string
  primaryPanelData: IPanelData
  optionalTestsData: IOptionalTestData[]
  isMobileOrderOpen: boolean
  setIsMobileOrderOpen: () => void
}

const PRIMARY_PANEL_TITLE = 'Primary panel'

const PanelCustomizationSection = ({
  productType,
  primaryPanelData,
  optionalTestsData,
  isMobileOrderOpen,
  setIsMobileOrderOpen,
}: IGeneCustomizationSectionProps) => {
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const isLocked = React.useMemo(() => checkIsLocked(productType), [productType])
  const {handleAddButtonClick, isGeneCountEnabled, showNameOnOrderTest, showAdded} =
    React.useContext(PanelDetailsContext)
  const totalCount = primaryPanelData.totalGenesCount ?? 0
  const geneListInTestDataExists = React.useMemo(
    () =>
      optionalTestsData &&
      optionalTestsData.some(item => {
        if (item.geneList) {
          return item.geneList.length > 0
        }
        return false
      }),
    [optionalTestsData],
  )
  const primaryTitle = React.useMemo(
    () => (showNameOnOrderTest(productType) ? primaryPanelData.title : PRIMARY_PANEL_TITLE),
    [showNameOnOrderTest, productType, primaryPanelData],
  )

  const shouldShowCustomizeText = React.useMemo(
    () => isGeneCountEnabled(primaryPanelData.panelCode ?? '') && (totalCount > 0 || geneListInTestDataExists),
    [],
  )

  const ADDONS_NO_COST = React.useMemo(
    () => (
      <div>
        <p>Add-ons can typically be added at no additional cost.</p>
        <p>
          See the <a href={addCountryCodePrefix(PROVIDERS_BILLING_LINK)}>billing page</a> for details
        </p>
      </div>
    ),
    [addCountryCodePrefix],
  )

  return (
    <OrderContainerWrapper
      handleAddButtonClick={handleAddButtonClick}
      isMobileOrderOpen={isMobileOrderOpen}
      pageType={TDetailsPageType.Panel}
      setIsMobileOrderOpen={setIsMobileOrderOpen}
      showAdded={showAdded}
      title="Order test"
    >
      {shouldShowCustomizeText && <p>You can customize this test by clicking genes to remove them.</p>}
      <PanelItemCustomization
        genesList={primaryPanelData.geneList ?? []}
        isLocked={isLocked}
        isPrimary={true}
        isRequired={true}
        panelCode={primaryPanelData.panelCode}
        panelTitle={primaryTitle ?? ''}
        totalGenesCount={primaryPanelData.totalGenesCount}
      />
      {optionalTestsData?.length > 0 && (
        <>
          <div className={styles.addOnGenesTitleWrapper}>
            <div className={styles.addOnGenesTitle}>Select add-on genes</div>
            <InfoTooltip overlayContent={ADDONS_NO_COST} />
          </div>
          {optionalTestsData?.map(item => (
            <PanelItemCustomization
              description={item.description ?? ''}
              genesList={item.geneList || []}
              isLocked={item.productType === PRODUCT_TYPE_RNA}
              isPrimary={false}
              isRequired={item.isRequired}
              key={item.title}
              panelCode={item.panelCode}
              panelTitle={item.title || ''}
              totalGenesCount={item.totalGenesCount}
            />
          ))}
        </>
      )}
    </OrderContainerWrapper>
  )
}

export default PanelCustomizationSection
