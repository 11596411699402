import * as React from 'react'
import classNames from 'classnames'

import {NvHeading} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'

import {parseHtmlTextile} from 'utils/utils'

import ClinicalSensitivityModal from './ClinicalSensitivityModal'

import styles from './ClinicalSensitivitySection.module.scss'

const CLINICAL_SENSITIVITY = 'Clinical sensitivity'
const CLINICAL_DESCRIPTION = 'Clinical description'

const ClinicalSensitivitySection = ({
  clinicalDescription,
  isSensitivity,
}: {
  clinicalDescription: string
  isSensitivity: boolean
}) => {
  const {linkClickLogEvent} = useAnalyticsQueue()

  const [isClinicalDescriptionModalOpen, setIsClinicalDescriptionModalOpen] = React.useState(false)
  const shouldApplyTextTile = React.useMemo(() => !!clinicalDescription?.match(/(external)/), [clinicalDescription])
  const description = React.useMemo(
    () => clinicalDescription && (shouldApplyTextTile ? parseHtmlTextile(clinicalDescription) : clinicalDescription),
    [shouldApplyTextTile, clinicalDescription],
  )

  const title = React.useMemo(() => (isSensitivity ? CLINICAL_SENSITIVITY : CLINICAL_DESCRIPTION), [isSensitivity])

  const handleShowMoreAnalyticsEvent = React.useCallback(() => {
    setIsClinicalDescriptionModalOpen(true)
    linkClickLogEvent({
      currentPage: `${title.toLowerCase()} section of panel page`,
      eventProperties: {
        referrer: document.referrer,
      },
      linkName: 'show more link',
    })
  }, [linkClickLogEvent, title])

  return (
    <>
      <div>
        <NvHeading className={styles.clinicalDescriptionTitle} element="h4" level={3}>
          {title}
        </NvHeading>
        <p
          className={classNames(styles.clinicalDescriptionText, {
            [styles.noWrapText]: shouldApplyTextTile,
          })}
        >
          {description}
        </p>
        {!shouldApplyTextTile && (
          <button
            className={classNames('nv-plain-action', styles.clinicalDescriptionLink)}
            data-testid="cs-show-more"
            onClick={handleShowMoreAnalyticsEvent}
          >
            Show more
          </button>
        )}
      </div>
      <ClinicalSensitivityModal
        clinicalDescription={clinicalDescription}
        onClose={() => setIsClinicalDescriptionModalOpen(false)}
        open={isClinicalDescriptionModalOpen}
        title={title}
      />
    </>
  )
}

export default ClinicalSensitivitySection
