import * as React from 'react'

// eslint-disable-next-line spellcheck/spell-checker
export const assayInfoC9orf72 = (
  <p>
    <strong>
      <em>Assay Information</em>
    </strong>
    <br />
    <br />
    <strong>Method</strong>
    <br />
    <br />
    This test is designed to detect and categorize hexanucleotide (GGGGCC) repeat units in intron 1 of the C9orf72 gene.
    Analysis of other variant types or other regions is not performed for this gene. Specific limitations in the
    analysis of this gene is included in the list of gene-specific limitations.
    <br />
    <br />
    The number of hexanucleotide repeat units in intron 1 of the C9orf72 gene is assessed by repeat-primed PCR (RP-PCR)
    with fluorescently labeled primers followed by fragment size analysis of the resulting amplicons via capillary
    electrophoresis (CE). This assay detects and appropriately accounts for the vast majority of indels directly
    downstream of the repeat region that occur in approximately 3% of the general population and 20-25% of individuals
    with an expansion in this region (PMID: 27936955). A secondary round of RP-PCR + CE, utilizing a non-overlapping set
    of primers to amplify the locus from the opposite direction, is used to confirm the initial call in the case of
    suspected allele sizes of 22 or more repeat units. This test can accurately (+/-1 repeat unit) determine repeat size
    up to 31 repeat units (Pathogenic full mutation cut-off), but is not designed to determine the exact number of
    repeat units over 31.
    <br />
    <br />
    Detected repeat expansions in C9orf72 are interpreted using a modified version of Invitae&apos;s Sherloc variant
    classification framework. Repeat size ranges- Benign (Normal Range): &lt;25 repeat units, Uncertain: 25-30 repeat
    units, Pathogenic (Full Mutation): &gt;=31 repeat units. Repeat lengths for normal and uncertain alleles are sized
    and included in the report. The exact size of expansion alleles over 30 repeats (full mutations) are not reported.
    <br />
    <br />
    <strong>Limitations and Disclaimer</strong>
    <br />
    <br />
    Based on validation study results and previous literature, this assay is expected to achieve &gt;95% analytical
    sensitivity and specificity for hexanucleotide repeat expansion detection. In rare cases, an individual may harbor
    sequence variation (insertions/deletions) in the region downstream of the GGGGCC repeat expansion locus. Although
    this assay detects and appropriately accounts for the vast majority of indels directly downstream of the repeat
    region, in rare cases we may not be able to PCR amplify both alleles, and a false negative result may be reported in
    which only a single allele size is called (presumed homozygosity). Due to somatic mosaicism, repeat size identified
    in DNA isolated from peripheral blood, buccal cells, or saliva may not reflect the repeat size in untested tissues
    (e.g. brain). In addition, a negative result does not definitively rule out the presence of an expansion in the
    mosaic state, as the current test is not validated to detect low-level mosaic variants. In very rare cases (such as
    circulating hematolymphoid neoplasm, bone marrow transplant, recent blood transfusion, or maternal cell
    contamination), the analyzed DNA may not represent the patient&apos;s constitutional genome. This test does not
    assess for methylation status.
    <br />
    <br />
    This test is intended to detect germline variants only. It may not be possible to fully resolve certain details
    about variants, such as mosaicism, phasing, or mapping ambiguity. This report reflects the analysis of an extracted
    genomic DNA sample. In very rare cases (such as circulating hematolymphoid neoplasm, bone marrow transplant, recent
    blood transfusion, or maternal cell contamination), the analyzed DNA may not represent the patient&apos;s
    constitutional genome.
    <br />
    <br />
  </p>
)

// eslint-disable-next-line spellcheck/spell-checker
export const assayInfoC9orf72Test = (
  <p>
    <br />
    <strong>
      <em>Assay Information</em>
    </strong>
    <br />
    <br />
    <strong>Method</strong>
    <br />
    <br />
    This test is designed to detect and categorize hexanucleotide (GGGGCC) repeat units in intron 1 of the C9orf72 gene.
    Analysis of other variant types or other regions is not performed for this gene. Specific limitations in the
    analysis of this gene is included in the list of gene-specific limitations.
    <br />
    <br />
    The number of hexanucleotide repeat units in intron 1 of the C9orf72 gene is assessed by repeat-primed PCR (RP-PCR)
    with fluorescently labeled primers followed by fragment size analysis of the resulting amplicons via capillary
    electrophoresis (CE). This assay detects and appropriately accounts for the vast majority of indels directly
    downstream of the repeat region that occur in approximately 3% of the general population and 20-25% of individuals
    with an expansion in this region (PMID: 27936955). A secondary round of RP-PCR + CE, utilizing a non-overlapping set
    of primers to amplify the locus from the opposite direction, is used to confirm the initial call in the case of
    suspected allele sizes of 22 or more repeat units. This test can accurately (+/-1 repeat unit) determine repeat size
    up to 31 repeat units (Pathogenic full mutation cut-off), but is not designed to determine the exact number of
    repeat units over 31.
    <br />
    <br />
    Detected repeat expansions in C9orf72 are interpreted using a modified version of Invitae&apos;s Sherloc variant
    classification framework. Repeat size ranges- Benign (Normal Range): &lt;25 repeat units, Uncertain: 25-30 repeat
    units, Pathogenic (Full Mutation): &gt;=31 repeat units. Repeat lengths for normal and uncertain alleles are sized
    and included in the report. The exact size of expansion alleles over 30 repeats (full mutations) are not reported.
    <br />
    <br />
    <strong>Limitations and Disclaimer</strong>
    <br />
    <br />
    Based on validation study results and previous literature, this assay is expected to achieve &gt;95% analytical
    sensitivity and specificity for hexanucleotide repeat expansion detection. In rare cases, an individual may harbor
    sequence variation (insertions/deletions) in the region downstream of the GGGGCC repeat expansion locus. Although
    this assay detects and appropriately accounts for the vast majority of indels directly downstream of the repeat
    region, in rare cases we may not be able to PCR amplify both alleles, and a false negative result may be reported in
    which only a single allele size is called (presumed homozygosity). Due to somatic mosaicism, repeat size identified
    in DNA isolated from peripheral blood, buccal cells, or saliva may not reflect the repeat size in untested tissues
    (e.g. brain). In addition, a negative result does not definitively rule out the presence of an expansion in the
    mosaic state, as the current test is not validated to detect low-level mosaic variants. In very rare cases (such as
    circulating hematolymphoid neoplasm, bone marrow transplant, recent blood transfusion, or maternal cell
    contamination), the analyzed DNA may not represent the patient&apos;s constitutional genome. This test does not
    assess for methylation status.
    <br />
    <br />
    This test is intended to detect germline variants only. It may not be possible to fully resolve certain details
    about variants, such as mosaicism, phasing, or mapping ambiguity. This report reflects the analysis of an extracted
    genomic DNA sample. In very rare cases (such as circulating hematolymphoid neoplasm, bone marrow transplant, recent
    blood transfusion, or maternal cell contamination), the analyzed DNA may not represent the patient&apos;s
    constitutional genome.
  </p>
)
