import * as React from 'react'

const InformationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M7.5 0C3.35816 0 0 3.35936 0 7.5C0 11.6431 3.35816 15 7.5 15C11.6418 15 15 11.6431 15 7.5C15 3.35936 11.6418 0 7.5 0ZM7.5 3.32661C8.20149 3.32661 8.77016 3.89528 8.77016 4.59677C8.77016 5.29827 8.20149 5.86694 7.5 5.86694C6.79851 5.86694 6.22984 5.29827 6.22984 4.59677C6.22984 3.89528 6.79851 3.32661 7.5 3.32661ZM9.19355 11.0081C9.19355 11.2085 9.03106 11.371 8.83064 11.371H6.16935C5.96894 11.371 5.80645 11.2085 5.80645 11.0081V10.2823C5.80645 10.0818 5.96894 9.91935 6.16935 9.91935H6.53226V7.98387H6.16935C5.96894 7.98387 5.80645 7.82138 5.80645 7.62097V6.89516C5.80645 6.69475 5.96894 6.53226 6.16935 6.53226H8.10484C8.30525 6.53226 8.46774 6.69475 8.46774 6.89516V9.91935H8.83064C9.03106 9.91935 9.19355 10.0818 9.19355 10.2823V11.0081Z"
      fill="#A8AAB3"
      fillRule="evenodd"
    />
  </svg>
)

export default InformationIcon
