import * as React from 'react'
import {useEffect, useRef} from 'react'

import {NvModal, useNvModal} from '@invitae/ids-react'

import styles from './PanelModal.module.scss'

interface IPanelModalProps {
  open: boolean
  onClose: () => void
  title: string
  children: any
}

const PanelModal = ({open, onClose, title, children}: IPanelModalProps) => {
  const modalRef = useRef(null)
  const {openModalDialog} = useNvModal(modalRef)

  useEffect(() => {
    if (open) {
      openModalDialog()
    }
  }, [open])

  return (
    <NvModal
      classes={{modal: styles.modalComponentWrapper, modalBody: styles.panelModalContent}}
      headerText={title}
      onDismiss={onClose}
      ref={modalRef}
      showCloseButton
    >
      {children}
    </NvModal>
  )
}

export default PanelModal
