import {GeneTestForPanelDetailedPageDto, OptTestForPanelDetailedPageDto} from '@invitae/stargate'

import {PRODUCT_TYPE_RNA} from 'constants/specialCases'

export const getOptInRNAGenesSet = (productType: string, optTests: OptTestForPanelDetailedPageDto[]) => {
  if (productType === PRODUCT_TYPE_RNA) {
    return optTests
      .filter(item => !!item.geneTests)
      .flatMap(item => (item.geneTests ? item.geneTests.map(el => el.name || '') : []))
  }
  return []
}

export const getAllGeneTests = (
  geneTests: GeneTestForPanelDetailedPageDto[],
  optTests: OptTestForPanelDetailedPageDto[],
) => {
  if (!geneTests.length && !optTests.length) return []

  const allGeneTests = geneTests.map(item => ({
    assayNotes: item.assayNotes,
    deldupAnalysis: item.deldupAnalysis,
    name: item.name,
    sequencingAnalysis: item.sequencingAnalysis,
    transcriptReference: item.transcriptReference,
  }))

  if (optTests.length) {
    const optGeneTests = optTests.flatMap(item => item.geneTests ?? [])
    allGeneTests.push(
      ...optGeneTests
        .filter(item => !allGeneTests.map(item => item.name!).includes(item.name!))
        .map(item => ({
          assayNotes: item?.assayNotes,
          deldupAnalysis: item?.deldupAnalysis,
          name: item?.name,
          sequencingAnalysis: item?.sequencingAnalysis,
          transcriptReference: item?.transcriptReference,
        })),
    )
  }

  return allGeneTests.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()))
}
