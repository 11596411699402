import * as React from 'react'

import {GeneV2Dto} from '@invitae/stargate'

import AssaysSection from 'components/PanelGeneDetail/AssaysSection'
import OrderingInformationSection from 'components/PanelGeneDetail/OrderingInformationSection'
import {TDetailsPageType} from 'constants/pageType'

import GeneDetailContext from '../GeneDetailContext'
import AliasSection from './AliasSection'
import AssociatedDisorders from './AssociatedDisorders'
import TabInfo from './TabInfo'

interface IGeneDetailProps {
  gene: GeneV2Dto
  showTabs?: boolean
}

const GeneDetailContent = ({gene, showTabs}: IGeneDetailProps) => {
  const {isCustomAssayEnabled} = React.useContext(GeneDetailContext)

  return (
    <>
      {showTabs && (
        <TabInfo assayNotes={gene.assayNotes ?? ''} name={gene.name ?? ''} productType={gene.productType ?? ''} />
      )}
      <AliasSection alias={gene.alias ?? ''} />
      <AssociatedDisorders associatedDisorders={gene.geneAssociatedDisorders ?? ''} />
      <OrderingInformationSection
        alternateSpecimens={gene.alternateSpecimens ?? ''}
        pageType={TDetailsPageType.Gene}
        preferredSpecimen={gene.preferredSpecimen ?? ''}
        tags={gene.tags}
        turnaroundTime={gene.turnaroundTime ?? ''}
      />
      <AssaysSection
        assayInformation={gene.assayInformation ?? ''}
        geneTests={[gene]}
        isGene
        pageType={TDetailsPageType.Gene}
        shouldShowAssayInfo={!isCustomAssayEnabled}
      />
    </>
  )
}

export default GeneDetailContent
