import * as React from 'react'

import {NvHeading} from '@invitae/ids-react'
import {useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'

import {NY_APPROVED} from 'constants/commonPanelData'
import {REQUEST_A_KIT_URL, SPECIMEN_AND_SHIPPING_REQUIREMENTS_URL} from 'constants/links'
import {TDetailsPageType} from 'constants/pageType'
import {getAnalyticsCurrentPageSection, parseHtml, parseHtmlTextile} from 'utils/utils'

import styles from './OrderingInformationSection.module.scss'

const LEARN_MORE_ABOUT_REQUIREMENTS = 'Learn more about specimen requirements'
const REQUEST_COLLECTION_KIT = 'Request a specimen collection kit'
const SECTION_NAME = 'test description'

interface IOrderingInformationSectionProps {
  preferredSpecimen: string
  alternateSpecimens: string
  turnaroundTime: string
  tags: string[] | undefined
  pageType: TDetailsPageType
  kitInstructions?: string
}

const OrderingInformationSection = ({
  preferredSpecimen,
  alternateSpecimens,
  turnaroundTime,
  tags,
  pageType,
  kitInstructions,
}: IOrderingInformationSectionProps) => {
  const {linkClickLogEvent} = useAnalyticsQueue()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const isNYApproved = React.useMemo(() => (tags ? tags.indexOf(NY_APPROVED) >= 0 : false), [tags])
  const handleLearnMoreAnalyticsEvent = React.useCallback(() => {
    linkClickLogEvent({
      currentPage: getAnalyticsCurrentPageSection(SECTION_NAME, pageType),
      eventProperties: {
        'Link destination': addCountryCodePrefix(SPECIMEN_AND_SHIPPING_REQUIREMENTS_URL),
        referrer: document.referrer,
      },
      linkName: LEARN_MORE_ABOUT_REQUIREMENTS.toLowerCase(),
    })
  }, [linkClickLogEvent, pageType])

  const handleRequestKitAnalyticsEvent = React.useCallback(() => {
    linkClickLogEvent({
      currentPage: getAnalyticsCurrentPageSection(SECTION_NAME, pageType),
      eventProperties: {
        'Link destination': REQUEST_A_KIT_URL,
        referrer: document.referrer,
      },
      linkName: REQUEST_COLLECTION_KIT.toLowerCase(),
    })
  }, [linkClickLogEvent, pageType])

  return (
    <div className={styles.orderingInformationWrapper}>
      <NvHeading className={styles.orderingInformationTitle} element="h4" level={3}>
        Ordering information
      </NvHeading>
      {turnaroundTime && turnaroundTime.length && (
        <span className={styles.informationText}>
          <p className={styles.informationTitle}>Turnaround time: </p>
          {turnaroundTime}
        </span>
      )}

      <span className={styles.informationText}>
        <p className={styles.informationTitle}>New York approved: </p>
        {isNYApproved ? 'Yes' : 'No'}
      </span>

      {preferredSpecimen && preferredSpecimen.length && (
        <span className={styles.informationText}>
          <p className={styles.informationTitle}>Preferred specimen: </p>
          {parseHtml(preferredSpecimen)}
        </span>
      )}
      {alternateSpecimens && alternateSpecimens.length && (
        <span className={styles.informationText}>
          <p className={styles.informationTitle}>Alternate specimens: </p>
          {alternateSpecimens}
        </span>
      )}
      <a
        className={styles.informationLink}
        data-testid="learn-more-about-requirements"
        href={addCountryCodePrefix(SPECIMEN_AND_SHIPPING_REQUIREMENTS_URL)}
        onClick={handleLearnMoreAnalyticsEvent}
      >
        {LEARN_MORE_ABOUT_REQUIREMENTS}
      </a>
      {kitInstructions ? (
        <span>{parseHtmlTextile(kitInstructions)}</span>
      ) : (
        <a data-testid="request-a-kit" href={REQUEST_A_KIT_URL} onClick={handleRequestKitAnalyticsEvent}>
          {REQUEST_COLLECTION_KIT}
        </a>
      )}
    </div>
  )
}

export default OrderingInformationSection
