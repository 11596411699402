import * as React from 'react'
import Skeleton from 'react-loading-skeleton'

import {useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'

import PanelCardsItem from 'components/PanelCardsSection/PanelCardsItem'
import {PRODUCT_TYPE_NIPS} from 'constants/specialCases'
import {PANEL, useGetLinkDetailPage} from 'hooks/useGetLinkToDetailPage'
import useGetPanelPrimaryAndTestData from 'hooks/useGetPanelPrimaryAndTestData.hooks'

import styles from './OtherTestsItemWrapper.module.scss'

const SKELETON_LINE_COUNT = 3
const SKELETON_LINE_HEIGHT_PX = 30

interface IOtherTestsFields {
  mainCode: string
}

const OtherTestsItemWrapper: React.FC<IOtherTestsFields> = ({mainCode}) => {
  const {panelDetailLink} = useGetLinkDetailPage(PANEL, mainCode)
  const {primaryPanelData, optionalTestList, isError} = useGetPanelPrimaryAndTestData(mainCode)
  const {linkClickLogEvent} = useAnalyticsQueue()
  const {removeUrlPrefix} = useCountryLocationPrefix()
  const handleRedirectToPanel = React.useCallback(() => {
    linkClickLogEvent({
      currentPage: 'other tests to consider section of panel page',
      eventProperties: {
        'Link destination': removeUrlPrefix(panelDetailLink),
        referrer: document.referrer,
      },
      linkName: 'view panel page',
    })
  }, [panelDetailLink, linkClickLogEvent])
  // we want it to fail silently and not render an error to the user
  if (isError) {
    return null
  }
  if (!primaryPanelData)
    return (
      <div className={styles.skeletonWrapper} data-testid="panel-cards-loading-skeleton" id="panelCardsLoadingSkeleton">
        <Skeleton count={SKELETON_LINE_COUNT} height={SKELETON_LINE_HEIGHT_PX} />
      </div>
    )

  return (
    <PanelCardsItem
      optionalTestsData={optionalTestList}
      primaryPanelData={primaryPanelData}
      showGeneCount={primaryPanelData.productType !== PRODUCT_TYPE_NIPS}
    >
      <a
        className={styles.titleRoot}
        data-testid="panel-cards-item-title-link"
        href={panelDetailLink}
        onClick={handleRedirectToPanel}
      >
        <p className={styles.cardTitle}>{primaryPanelData?.title}</p>
      </a>
    </PanelCardsItem>
  )
}

export default OtherTestsItemWrapper
