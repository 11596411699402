import * as React from 'react'
import classNames from 'classnames'

import {NvTooltip} from '@invitae/ids-react'

import PanelDetailsContext from 'components/PanelDetail/PanelDetailContext'
import {getSelectedPanelGeneCountFromTotalUnselectedGeneList} from 'components/PanelDetail/panelDetailUtils'
import {IPanelGene} from 'hooks/types'

import GeneTileItem from './GeneTileItem/index'

import styles from './GeneTiles.module.scss'

export interface IGeneTilesProps {
  geneTests: IPanelGene[]
  panelCode: string
  isRequired?: boolean
  isLocked?: boolean
  isPrimary: boolean
  enableWideTiles?: boolean
}

const COLLAPSED_GENES_COUNT = 14
export const ONE_GENE_MUST_BE_SELECTED = 'At least one gene in the primary panel must be selected.'

const GeneTiles = ({
  geneTests,
  isRequired = false,
  isPrimary,
  panelCode,
  enableWideTiles = false,
  isLocked = false,
}: IGeneTilesProps) => {
  const {onGeneSelect, unselectedGenes} = React.useContext(PanelDetailsContext)
  const isShowAllGenesDisplayed = geneTests.length > COLLAPSED_GENES_COUNT
  const [isAllGenesDisplayed, setIsAllGenesDisplayed] = React.useState(false)
  const geneTilesListRef = React.useRef<null | HTMLDivElement>(null)

  const orderedGeneTests = React.useMemo(
    () => geneTests.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    [geneTests],
  )

  const shouldShowWarning = React.useCallback(
    (gene: IPanelGene) => {
      const selectedGenesCount = getSelectedPanelGeneCountFromTotalUnselectedGeneList(unselectedGenes, orderedGeneTests)
      return selectedGenesCount === 1 && !unselectedGenes.some(item => item.name === gene.name)
    },
    [unselectedGenes, orderedGeneTests],
  )

  const visibleGenes = React.useMemo(
    () => (isShowAllGenesDisplayed ? orderedGeneTests.slice(0, COLLAPSED_GENES_COUNT) : orderedGeneTests),
    [orderedGeneTests, isShowAllGenesDisplayed],
  )

  const displayedGenes = React.useMemo(
    () => (isAllGenesDisplayed ? orderedGeneTests : visibleGenes),
    [orderedGeneTests, visibleGenes, isAllGenesDisplayed],
  )

  const onClickShowAllGenes = () => {
    setIsAllGenesDisplayed(!isAllGenesDisplayed)
  }

  const onSelect = React.useCallback(
    (geneItem: IPanelGene) => {
      onGeneSelect(geneItem, panelCode, isPrimary, isRequired)
    },
    [isPrimary, onGeneSelect, isRequired, panelCode],
  )

  return (
    <div className={styles.geneTilesContainer} data-testid="gene-tiles-container" id="geneTilesContainer">
      <div
        className={classNames(styles.geneTilesWrapper, {
          [styles.notClickable]: isLocked,
        })}
        ref={geneTilesListRef}
      >
        {displayedGenes.map(gene => {
          if (shouldShowWarning(gene) && (isPrimary || isRequired) && displayedGenes.length > 1) {
            return (
              <NvTooltip hasChildrenWrapper={false} key={gene.name} text={ONE_GENE_MUST_BE_SELECTED}>
                <div
                  className={classNames(styles.tooltipButton, {[styles.wideTiles]: enableWideTiles})}
                  data-testid="tooltip-trigger"
                >
                  <GeneTileItem geneItem={gene} onSelect={onSelect} />
                </div>
              </NvTooltip>
            )
          }
          return (
            <div
              className={classNames(styles.tooltipButton, {[styles.wideTiles]: enableWideTiles})}
              data-testid="gene-tile-item-wrapper"
              key={gene.name}
            >
              <GeneTileItem geneItem={gene} onSelect={onSelect} />
            </div>
          )
        })}
      </div>
      {isShowAllGenesDisplayed && (
        <div
          className={styles.showAllGenes}
          data-testid="show-all-genes"
          onClick={onClickShowAllGenes}
          onKeyDown={e => (e.key === 'Enter' ? onClickShowAllGenes() : null)}
          role="button"
          tabIndex={0}
        >
          {!isAllGenesDisplayed ? 'Show all genes' : 'Collapse genes'}
        </div>
      )}
    </div>
  )
}

export default GeneTiles
