import React from 'react'
import BaseTestCatalogComponent from 'BaseTestCatalogComponent/BaseTestCatalogComponent'
import {Entry} from 'contentful'

import {ILayoutFooterFields, INavigationHeaderFields} from '@invitae/contentful-types'
import {BaseNucleobaseComponent} from '@invitae/nucleobase'
import {GeneV2Dto, TestPanelDetailedPageDto} from '@invitae/stargate'

import MetaData from 'components/Metadata/Metadata'

interface TestCatalogLayoutProps extends React.PropsWithChildren<unknown> {
  header: Entry<INavigationHeaderFields> | null
  footer: Entry<ILayoutFooterFields> | null
  panelData?: TestPanelDetailedPageDto
  genes?: GeneV2Dto[]
}

export const TestCatalogLayout = ({header, panelData, children, genes, footer}: TestCatalogLayoutProps) => {
  return (
    <>
      <MetaData genes={genes} panel={panelData?.panel} />
      {header && <BaseTestCatalogComponent BaseComponent={BaseNucleobaseComponent} source={header} />}
      {children}
      {footer && <BaseTestCatalogComponent BaseComponent={BaseNucleobaseComponent} source={footer} />}
    </>
  )
}

export default TestCatalogLayout
