import * as React from 'react'
import classNames from 'classnames'

import {NvHeading, NvTooltip} from '@invitae/ids-react'

import GeneListTooltip from 'components/GeneListTooltip'

import PanelDetailsContext from '../PanelDetailContext'

import styles from './GeneralInfo.module.scss'

const PANEL_IMAGE = 'https://invitae.imgix.net/web/test-catalog/kit-box-image-panel-card.png?h=200&q=10&auto=format'

interface IPanelInfoProps {
  name: string
}

const GeneralInfo = ({name}: IPanelInfoProps) => {
  const {
    totalGenesCount,
    selectedPanels,
    primaryPanelData,
    optionalTestsData,
    isAddonProvided,
    cleanPanelCode,
    isGeneCountEnabled,
  } = React.useContext(PanelDetailsContext)

  const showGeneCount = React.useMemo(
    () => totalGenesCount > 0 && isGeneCountEnabled(name),
    [totalGenesCount, isGeneCountEnabled],
  )

  return (
    <div className={styles.panelInfoWrapper}>
      <div className={styles.panelImageWrapper}>
        <img alt="Card kit" className={styles.cardImage} src={PANEL_IMAGE} />
      </div>
      <div className={styles.panelTextContainer}>
        <NvHeading className={styles.panelRegularTitle} element="h3" level={1}>
          {name}
        </NvHeading>
        <div className={styles.panelAdditionalData}>
          <p>
            Test code: {cleanPanelCode} {showGeneCount && '•'}
          </p>
          <div className={styles.tooltipWrapper}>
            {showGeneCount && (
              <NvTooltip
                limitedWidth={false}
                overlayContent={
                  <GeneListTooltip
                    optionalTestList={optionalTestsData}
                    primaryPanelData={primaryPanelData}
                    selectedPanels={selectedPanels}
                  />
                }
                showCloseButtonTop
                trigger="click"
              >
                <button
                  className={classNames(styles.cardGeneCount, 'nv-plain-action')}
                  data-testid="panel-item-gene-count-tooltip"
                  type="button"
                >
                  <div className={styles.cardGeneCountInnerContent}>
                    {isAddonProvided ? (
                      <>Up&nbsp;to&nbsp;{totalGenesCount} genes</>
                    ) : (
                      <>
                        {totalGenesCount} {totalGenesCount > 1 ? 'genes' : 'gene'}
                      </>
                    )}
                  </div>
                </button>
              </NvTooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralInfo
