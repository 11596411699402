import * as React from 'react'

import {NvHeading} from '@invitae/ids-react'

import styles from './GeneDetailContent.module.scss'

const AliasSection = ({alias}: {alias: string}) => {
  if (!alias || (alias && !alias.length)) return null

  return (
    <div className={styles.aliasWrapper}>
      <NvHeading className={styles.aliasTitle} element="h4" level={3}>
        Alias
      </NvHeading>
      <p>{alias}</p>
    </div>
  )
}

export default AliasSection
