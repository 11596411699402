import * as React from 'react'

import PanelModal from 'components/PanelGeneDetail/PanelModal'
import {parseHtmlTextile} from 'utils/utils'

import styles from './ClinicalSensitivitySection.module.scss'

interface IClinicalSensitivityModalProps {
  open: boolean
  onClose: () => void
  clinicalDescription: string
  title: string
}

const ClinicalSensitivityModal = ({open, onClose, clinicalDescription, title}: IClinicalSensitivityModalProps) => {
  return (
    <PanelModal onClose={onClose} open={open} title={title}>
      <p className={styles.clinicalDescriptionModalText}>{parseHtmlTextile(clinicalDescription)}</p>
    </PanelModal>
  )
}

export default ClinicalSensitivityModal
