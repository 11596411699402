import * as React from 'react'
import {contentfulClientCredentials} from 'config'
import {Entry} from 'contentful'
import {GetServerSideProps, GetServerSidePropsResult} from 'next'

import {ILayoutFooterFields, INavigationHeaderFields, IPageFields} from '@invitae/contentful-types'
import {
  addCountryCodeToPath,
  CountryCode,
  countryCodeIndicatorFromPath,
  getFeatureFlags,
  getRedirect,
} from '@invitae/nucleobase'
import {CategoryV2Dto, TestPanelDetailedPageDto} from '@invitae/stargate'
import {GeneV2Dto} from '@invitae/stargate'

import fetchContentfulPageData from 'api/contentful/fetchContentfulPageData'
import fetchGeneDetailedPageData from 'api/gene/fetchGeneDetailedPageData'
import fetchMainCategoryPageData from 'api/mainCategory/fetchMainCategoryPageData'
import fetchPanelDetailedPageData from 'api/panel/fetchPanelDetailedPageData'
import {SponsoredTestingType} from 'api/types'
import GeneDetail from 'components/GeneDetail'
import {cleanUpUrlFromParams, mainCategoryPaths, pureContentfulPagesPaths} from 'components/MainCategory/utils'
import PanelDetail from 'components/PanelDetail'
import TestCatalogLayout from 'components/TestCatalogLayout/TestCatalogLayout'
import {URL_GENE_REGEX, URL_TEST_REGEX} from 'constants/specialCases'
import NotFoundPage from 'pages/404'
import InternalServerErrorPage from 'pages/500'

import {flagNames} from '../flagNames'

export interface PageProps {
  errorCode?: string
  panelData?: TestPanelDetailedPageDto
  geneData?: GeneV2Dto[]
  header: Entry<INavigationHeaderFields> | null
  sponsoredTestingData: SponsoredTestingType
  footer: Entry<ILayoutFooterFields> | null
  page?: Entry<IPageFields>
  featureFlags?: Record<string, boolean>
  domain?: string
  countryCode?: CountryCode
  mainCategoryData?: CategoryV2Dto
}

const CatchAllPage: React.FC<PageProps> = ({
  sponsoredTestingData,
  errorCode,
  panelData,
  geneData,
  header,
  footer,
  featureFlags,
}) => {
  if (errorCode) {
    return <InternalServerErrorPage />
  }
  if (panelData) {
    return (
      <TestCatalogLayout footer={footer} header={header} panelData={panelData}>
        <PanelDetail panel={panelData.panel} partnerships={sponsoredTestingData} />
      </TestCatalogLayout>
    )
  }

  if (geneData && geneData.length) {
    return (
      <TestCatalogLayout footer={footer} genes={geneData} header={header}>
        <GeneDetail featureFlags={featureFlags} genes={geneData} />
      </TestCatalogLayout>
    )
  }

  return <NotFoundPage />
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  /**
   * get feature flags and return it to _app.tsx
   */
  const featureFlagsData = await getFeatureFlags({
    appName: 'test-catalog-frontend',
    flagNames,
    projectName: 'customer-experience',
  })

  const domain = ctx?.req?.headers?.host

  const featureFlags = featureFlagsData?.data ?? {}

  let pageProps: Partial<Record<'notFound' | 'props' | 'redirect', any>> = {}

  // if the path is present in the list of the main categories paths list, apply the logic for fetching main-category data
  const cleanedUrl = cleanUpUrlFromParams(ctx.resolvedUrl)
  const url = cleanedUrl === '/' ? '' : cleanedUrl
  const [countryCodeFromPath, path] = countryCodeIndicatorFromPath(url)

  /**
   * Pages can't render without a country code in the URL
   * This code below prevents to render page without a countryCode and uses a default value instead
   */
  if (!countryCodeFromPath) {
    return {
      redirect: {
        destination: addCountryCodeToPath('US', path),
        permanent: false,
      },
    }
  }

  const countryCode = countryCodeFromPath.toUpperCase() as CountryCode

  if (pureContentfulPagesPaths.includes(path)) {
    pageProps = await fetchContentfulPageData(ctx, url)
  } else if (mainCategoryPaths.includes(path)) {
    pageProps = await fetchMainCategoryPageData(ctx)
  } else if (path.match(URL_TEST_REGEX)) {
    pageProps = await fetchPanelDetailedPageData(url, countryCode)
  } else if (path.match(URL_GENE_REGEX)) {
    pageProps = await fetchGeneDetailedPageData(url, countryCode)
  } else {
    pageProps = {
      // returns the default 404 page with a status code of 404
      notFound: true,
    }
  }

  if (pageProps?.notFound) {
    // Check if there's a redirect for this path in Contentful
    const redirect = await getRedirect({
      contentfulClientCredentials,
      countryCode,
      path,
      usePreviewClient: !!process.env.ENABLE_CONTENTFUL_PREVIEW_MODE,
    })
    if (redirect) {
      pageProps = {
        redirect: {
          destination: addCountryCodeToPath(countryCodeFromPath, redirect.fields.redirectTo),
          permanent: true,
        },
      }
    }
  }

  if (pageProps?.redirect || pageProps?.notFound) {
    return pageProps as GetServerSidePropsResult<any>
  }

  return {
    ...pageProps,
    props: {
      ...pageProps?.props,
      countryCode,
      domain,
      featureFlags,
    },
  } as GetServerSidePropsResult<PageProps>
}

export default CatchAllPage
