import * as React from 'react'

import PanelModal from 'components/PanelGeneDetail/PanelModal'
import {parseHtmlTextile} from 'utils/utils'

import styles from '../ClinicalInformation.module.scss'

interface IClinicalSensitivityDescriptionModalProps {
  open: boolean
  onClose: () => void
  clinicalDescription: string
  testSensitivity: string
}

const ClinicalSensitivityDescriptionModal = ({
  open,
  onClose,
  clinicalDescription,
  testSensitivity,
}: IClinicalSensitivityDescriptionModalProps) => {
  return (
    <PanelModal onClose={onClose} open={open} title="Clinical description and sensitivity">
      <span className={styles.informationText}>
        <p className={styles.informationTitle}>Clinical description: </p>
        {parseHtmlTextile(clinicalDescription)}
      </span>
      <br />
      <span className={styles.informationText}>
        <p className={styles.informationTitle}>Clinical sensitivity: </p>
        <br />
        {parseHtmlTextile(testSensitivity)}
      </span>
    </PanelModal>
  )
}

export default ClinicalSensitivityDescriptionModal
