import * as React from 'react'
import {PanelCardsItemIndexContext} from 'wrapper/PanelCardsContext'

import {AddOnDto, GroupTestDto} from '@invitae/stargate'

import PanelCardsSection from 'components/PanelCardsSection'
import OtherTestsItemWrapper from 'components/PanelGeneDetail/OtherTestsItemWrapper/OtherTestsItemWrapper'
import {GREY_BACKGROUND} from 'constants/theme'
import {getCleanCode} from 'utils/utils'

import styles from './OrderTest.module.scss'

interface IOrderTestProps {
  tests: GroupTestDto[]
  optTests: AddOnDto[]
  customRef: React.RefObject<HTMLDivElement>
}

const OrderTest = ({tests, optTests, customRef}: IOrderTestProps) => {
  const [items, setItems] = React.useState<React.ReactNode[]>([])
  // filters out duplicate group test by base PR code
  const panels = tests.length ? tests : optTests.flatMap(opt => opt.groupTests)
  const filteredTests = React.useMemo(() => [...new Set(panels.map(test => getCleanCode(test?.code ?? '')))], [panels])

  React.useEffect(() => {
    if (panels.length) {
      setItems(
        filteredTests.map((item: string, index: number) => (
          <PanelCardsItemIndexContext.Provider key={`PanelCardItem_${item}_${index}`} value={index + 1}>
            <OtherTestsItemWrapper mainCode={item} />
          </PanelCardsItemIndexContext.Provider>
        )),
      )
    }
  }, [panels])

  if (!panels.length) return null

  return (
    <div className={styles.testsWrapper} ref={customRef}>
      <PanelCardsSection
        id="order-test-panel-cards"
        internalName="Order a test"
        items={items}
        key="order-test-panel-cards"
        showBreadcrumbs={false}
        subHeading="Order a test"
        subtext={`See Invitae tests that include this gene${
          !tests.length && optTests.length
            ? ' (Note: this gene is only available as a separate add-on to the following tests)'
            : ''
        }`}
        theme={GREY_BACKGROUND}
      />
    </div>
  )
}

export default OrderTest
