import * as React from 'react'
import InformationIcon from 'icons/InformationIcon'

import {NvTooltip} from '@invitae/ids-react'

import styles from './InfoTooltip.module.css'

interface IInfoTooltipProps {
  tooltipText?: string
  overlayContent?: React.ReactNode
}

const InfoTooltip = ({tooltipText, overlayContent}: IInfoTooltipProps) => {
  return (
    <NvTooltip overlayContent={overlayContent} text={tooltipText}>
      <InformationIcon className={styles.infoIcon} />
    </NvTooltip>
  )
}

export default InfoTooltip
