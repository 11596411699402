import * as React from 'react'
import classNames from 'classnames'

import {CircleVariant, ColorVariant, IconCheckmark, SizeVariant} from '@invitae/iconograph-react'

import PanelDetailsContext from 'components/PanelDetail/PanelDetailContext'
import {IPanelGene} from 'hooks/types'

import styles from './GeneTileItem.module.scss'

export interface IGenesTileItemProps {
  onSelect: (geneItem: IPanelGene) => void
  geneItem: IPanelGene
}

const GeneTileItem = ({geneItem, onSelect}: IGenesTileItemProps) => {
  const {unselectedGenes} = React.useContext(PanelDetailsContext)

  const isSelected = React.useMemo(
    () => !unselectedGenes.some(item => item.name === geneItem.name),
    [unselectedGenes, geneItem],
  )

  return (
    <div
      className={classNames(styles.root, {
        [styles.selected]: isSelected,
        [styles.unselected]: !isSelected,
      })}
      data-testid="gene-tile-item"
      onClick={() => onSelect(geneItem)}
      onKeyDown={e => (e.key === 'Enter' ? onSelect(geneItem) : null)}
      role="button"
      tabIndex={0}
    >
      {isSelected && (
        <IconCheckmark
          circle={CircleVariant.no}
          className={styles.checkIcon}
          color={ColorVariant.none}
          currentColor
          size={SizeVariant.small}
          style={{
            fill: 'white',
            width: 11,
          }}
        />
      )}
      <div className={classNames(styles.geneTitle, {[styles.unselectedGeneTitle]: !isSelected})}>{geneItem.name}</div>
    </div>
  )
}

export default GeneTileItem
