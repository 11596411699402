import * as React from 'react'

import ClinicalSensitivityDescriptionSection from './ClinicalSensitivityDescriptionSection'
import ClinicalSensitivitySection from './ClinicalSensitivitySection'

import styles from './ClinicalInformation.module.scss'

interface IClinicalSensitivityDescriptionProps {
  clinicalDescription: string
  testSensitivity: string
}

const ClinicalInformation = ({clinicalDescription, testSensitivity}: IClinicalSensitivityDescriptionProps) => {
  const description = React.useMemo(() => {
    if (clinicalDescription && clinicalDescription.length) return clinicalDescription
    else if (testSensitivity && testSensitivity.length) return testSensitivity
    return ''
  }, [clinicalDescription, testSensitivity])

  if (!description.length) return null

  return (
    <>
      <div className={styles.clinicalDescriptionWrapper}>
        {clinicalDescription?.length && testSensitivity?.length ? (
          <ClinicalSensitivityDescriptionSection
            clinicalDescription={clinicalDescription}
            testSensitivity={testSensitivity}
          />
        ) : (
          <ClinicalSensitivitySection clinicalDescription={description} isSensitivity={!!testSensitivity} />
        )}
      </div>
    </>
  )
}

export default ClinicalInformation
