import * as React from 'react'
import classNames from 'classnames'

import {NvHeading} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'

import {parseHtmlTextile} from 'utils/utils'

import AssociatedDisordersModal from './AssociatedDisordersModal'

import styles from './AssociatedDisorders.module.scss'

const LINE_HEIGHT = 24

interface IAssociatedDisordersProps {
  associatedDisorders: string
}

const AssociatedDisorders = ({associatedDisorders}: IAssociatedDisordersProps) => {
  const {linkClickLogEvent} = useAnalyticsQueue()
  const disordersRef = React.useRef<null | HTMLDivElement>(null)
  const [isDisordersModalOpen, setIsDisordersModalOpen] = React.useState(false)
  const [showMore, setShowMore] = React.useState(false)

  React.useEffect(() => {
    if (disordersRef.current) {
      setShowMore(disordersRef.current.offsetHeight > LINE_HEIGHT * 2)
    }
  }, [associatedDisorders])

  const handleShowMoreClick = React.useCallback(() => {
    linkClickLogEvent({
      currentPage: 'associated disorders section of gene page',
      eventProperties: {
        referrer: document.referrer,
      },
      linkName: 'show all link',
    })
    setIsDisordersModalOpen(true)
  }, [linkClickLogEvent])

  if (!associatedDisorders || (associatedDisorders && !associatedDisorders.length)) return null

  return (
    <>
      <div className={styles.associatedDisordersWrapper}>
        <NvHeading className={styles.associatedDisordersTitle} element="h4" level={3}>
          Associated disorders
        </NvHeading>
        <div
          className={classNames(styles.associatedDisordersContent, {
            [styles.showThreeDots]: showMore,
          })}
          ref={disordersRef}
        >
          {parseHtmlTextile(associatedDisorders)}
        </div>
        {showMore && (
          <button
            className={classNames('nv-plain-action', styles.disordersLink)}
            data-testid="associated-disorders-show-more"
            onClick={handleShowMoreClick}
          >
            Show more
          </button>
        )}
      </div>
      <AssociatedDisordersModal
        associatedDisorders={associatedDisorders}
        onClose={() => setIsDisordersModalOpen(false)}
        open={isDisordersModalOpen}
      />
    </>
  )
}

export default AssociatedDisorders
