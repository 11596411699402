import * as React from 'react'
import classNames from 'classnames'

import {NvHeading} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'

import {parseHtmlTextile} from 'utils/utils'

import ClinicalSensitivityDescriptionModal from './ClinicalSensitivityDescriptionModal'

import styles from '../ClinicalInformation.module.scss'

interface IClinicalSensitivityDescriptionProps {
  clinicalDescription: string
  testSensitivity: string
}

const ClinicalSensitivityDescriptionSection = ({
  clinicalDescription,
  testSensitivity,
}: IClinicalSensitivityDescriptionProps) => {
  const {linkClickLogEvent} = useAnalyticsQueue()

  const [isClinicalDescriptionModalOpen, setIsClinicalDescriptionModalOpen] = React.useState(false)

  const handleShowMoreAnalyticsEvent = React.useCallback(() => {
    setIsClinicalDescriptionModalOpen(true)
    linkClickLogEvent({
      currentPage: 'clinical sensitivity and description sections of panel page',
      eventProperties: {
        referrer: document.referrer,
      },
      linkName: 'show more link',
    })
  }, [linkClickLogEvent])

  return (
    <>
      <div>
        <NvHeading className={styles.clinicalDescriptionTitle} element="h4" level={3}>
          Clinical description and sensitivity
        </NvHeading>
        <span className={classNames(styles.informationText, styles.informationNoWrap)}>
          <p className={styles.informationTitle}>Clinical description: </p>
          {parseHtmlTextile(clinicalDescription)}
        </span>
        <button
          className={classNames('nv-plain-action', styles.clinicalDescriptionLink)}
          data-testid="cs-show-more"
          onClick={handleShowMoreAnalyticsEvent}
        >
          Show more
        </button>
      </div>
      <ClinicalSensitivityDescriptionModal
        clinicalDescription={clinicalDescription}
        onClose={() => setIsClinicalDescriptionModalOpen(false)}
        open={isClinicalDescriptionModalOpen}
        testSensitivity={testSensitivity}
      />
    </>
  )
}

export default ClinicalSensitivityDescriptionSection
