import * as React from 'react'

import {useTabs} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'
import {GeneV2Dto} from '@invitae/stargate'

import OrderContainerWrapper from 'components/PanelGeneDetail/OrderContainerWrapper'
import {CONFIRM_ADD_TO_CART_ANALYTICS_EVENT} from 'constants/analytics'
import {TDetailsPageType} from 'constants/pageType'
import useHandleGeneSelection from 'hooks/useHandleGeneSelection.hooks'
import {getGeneByType} from 'utils/utils'

import GeneItem from './GeneItem'

export interface IGeneSelectSectionProps {
  isMobileOrderOpen: boolean
  setIsMobileOrderOpen: () => void
  genes: GeneV2Dto[]
  shouldOpenModal: boolean
}

const GeneSelectSection = ({
  isMobileOrderOpen,
  setIsMobileOrderOpen,
  genes,
  shouldOpenModal,
}: IGeneSelectSectionProps) => {
  const {logEvent} = useAnalyticsQueue()
  const {selectTab, currentTab} = useTabs()

  const selectedGene = getGeneByType(genes, currentTab) as GeneV2Dto

  const logAddToCartEvent = React.useCallback(() => {
    logEvent({
      eventName: CONFIRM_ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'Gene Name': selectedGene.name,
        'Product Type': selectedGene.productType,
      },
    })
  }, [selectedGene, logEvent])

  const {showAdded, handleAddToCart} = useHandleGeneSelection({geneCode: selectedGene.code!, logAddToCartEvent})

  return (
    <OrderContainerWrapper
      handleAddButtonClick={handleAddToCart}
      isMobileOrderOpen={isMobileOrderOpen}
      pageType={TDetailsPageType.Gene}
      selectedGene={selectedGene.code}
      setIsMobileOrderOpen={setIsMobileOrderOpen}
      shouldOpenModal={shouldOpenModal}
      showAdded={showAdded}
      title="Order gene"
    >
      {genes.map(geneItem => (
        <GeneItem
          isSingleGene={genes.length <= 1}
          key={geneItem.code}
          onClick={() => geneItem.productType && selectTab(geneItem.productType)}
          selected={selectedGene.productType === geneItem.productType}
          title={`${geneItem.name} - ${geneItem.productType}`}
        />
      ))}
    </OrderContainerWrapper>
  )
}

export default GeneSelectSection
