import * as React from 'react'

import {NvHeading} from '@invitae/ids-react'

import SeeInvitaeTestsText from '../SeeInvitaeTestsText'

import styles from './GeneDetailContent.module.scss'

interface ITabInfoProps {
  name: string
  productType: string
  assayNotes: string
}

const TabInfo = ({name, productType, assayNotes}: ITabInfoProps) => {
  return (
    <div className={styles.tabInfoWrapper}>
      <NvHeading className={styles.geneNameType} element="h3" level={2}>
        {name} - {productType}
      </NvHeading>
      <SeeInvitaeTestsText />
      {!!assayNotes.length && <p className={styles.assayNotes}>{assayNotes}</p>}
    </div>
  )
}

export default TabInfo
