// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable spellcheck/spell-checker */
import * as React from 'react'

import {NvButton, NvHeading} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'

import {assayInfoC9orf72Test} from 'components/GeneDetail/assayInfo'
import {parseHtmlTextile} from 'utils/utils'

import PanelDetailsContext from '../PanelDetailContext'
import DisordersTestedModal from './DisordersTestedModal'

import styles from './TestDescriptionSection.module.scss'

interface IPanelDescriptionProps {
  description: string
  disordersTested: string
  code?: string
}

const TestDescriptionSection = ({description, disordersTested}: IPanelDescriptionProps) => {
  const {linkClickLogEvent} = useAnalyticsQueue()
  const [isDisordersTestedModalOpened, setIsDisordersTestedModalOpened] = React.useState(false)
  const {isCustomAssayEnabled} = React.useContext(PanelDetailsContext)

  const clickSeeAllDisordersTested = React.useCallback(() => {
    linkClickLogEvent({
      currentPage: 'test description section of panel page',
      eventProperties: {
        referrer: document.referrer,
      },
      linkName: 'see all disorders tested link',
    })
    setIsDisordersTestedModalOpened(true)
  }, [linkClickLogEvent])

  return (
    <>
      <div className={styles.panelDescriptionWrapper}>
        <NvHeading className={styles.panelDescriptionTitle} element="h4" level={3}>
          Test description
        </NvHeading>
        <div className={styles.panelDescriptionText}>
          {parseHtmlTextile(description)}
          {isCustomAssayEnabled ? assayInfoC9orf72Test : ''}
        </div>
        {disordersTested && disordersTested.length && (
          <NvButton
            dataTestId="see-all-disorders-tested"
            onClick={clickSeeAllDisordersTested}
            size="small"
            variant="secondary"
          >
            See all disorders tested
          </NvButton>
        )}
      </div>
      <DisordersTestedModal
        disordersTested={disordersTested}
        onClose={() => setIsDisordersTestedModalOpened(false)}
        open={isDisordersTestedModalOpened}
      />
    </>
  )
}

export default TestDescriptionSection
