import * as React from 'react'
import {sentenceCase} from 'change-case'
import {headerConfig} from 'config'
import Head from 'next/head'
import {useRouter} from 'next/router'

import {countryCodeToLocale, CountryLocationContext} from '@invitae/nucleobase'
import {GeneV2Dto, PanelDetailedPageDto} from '@invitae/stargate'

import {normalizeLocale} from 'utils/pageHeaderUtils'
export const GENERIC_TITLE = 'Test catalog | Invitae'
export const META_IMAGE_URL = 'https://www.invitae.com/static/img/common/logo-share.png'
export const APPLICATION_COLOR = '#ffffff'
interface MetadataProps {
  panel?: PanelDetailedPageDto
  genes?: GeneV2Dto[]
}

const Metadata: React.FC<MetadataProps> = ({panel, genes}) => {
  const router = useRouter()
  const {canonicalPathname} = headerConfig({
    currentPath: router.asPath,
  })
  const {countryCode} = React.useContext(CountryLocationContext)
  const metaData = React.useMemo(() => {
    // adding this check to be able to get meta data from gene as well
    if (panel) {
      return {
        category: panel.categories?.find(category => category.conceptType === 'main_category')?.displayName,
        code: panel.code,
        genes: panel.geneTests?.map(geneTest => geneTest.name).join(),
        metaDescription: panel.metaDescription,
        tabTitle: panel.name,
      }
    } else if (genes) {
      const gene = genes[0]
      return {
        code: gene.code,
        genes: gene.name,
        metaDescription: gene.geneAssociatedDisorders,
        tabTitle: gene.name,
      }
    }
    return {
      tabTitle: '',
    }
  }, [panel, genes])
  const {category, code, genes: genesMetadata, metaDescription, tabTitle} = metaData
  return (
    <Head>
      <title>{tabTitle ? `${tabTitle} | ${GENERIC_TITLE}` : GENERIC_TITLE}</title>
      {metaDescription && <meta content={metaDescription} name="description" />}
      {metaDescription && <meta content={metaDescription} name="og:description" />}
      <meta content={tabTitle ? `${tabTitle} | ${GENERIC_TITLE}` : GENERIC_TITLE} name="og:title" />
      <meta content="website" name="og:type" />
      {canonicalPathname && <meta content={canonicalPathname} name="og:url" />}
      <meta content={process.env.FACEBOOK_VERIFICATION} name="facebook-domain-verification" />
      <meta content={META_IMAGE_URL} name="og:image" />
      <meta content={normalizeLocale(countryCodeToLocale[countryCode])} name="og:locale" />
      <meta content="Invitae" name="application-name" />
      <meta content={APPLICATION_COLOR} name="theme-color" />
      {genesMetadata && <meta content={genesMetadata} name="genes" />}
      {code && <meta content={code} name="code" />}
      {/* regex to match the PR code format (PR02101.35) */}
      {code?.match(/PR[-+]?[0-9]*\.?[0-9]*/) && (
        <meta content={code.split('.')[0]?.replace(/\D/g, '')} name="code_search" />
      )}
      {category && <meta content={sentenceCase(category)} name="categories" />}
      {metaDescription && <meta content={metaDescription} name="test_description" />}
      <meta content={tabTitle} name="name" />
      {canonicalPathname && <link href={canonicalPathname} rel="canonical" />}
    </Head>
  )
}

export default Metadata
