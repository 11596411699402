import * as React from 'react'
import {noop} from 'lodash'

export type IGeneDetailContext = {
  scrollToOtherTests: () => void
  productType: string
  isCustomAssayEnabled: boolean
}

const GeneDetailContext = React.createContext<IGeneDetailContext>({
  isCustomAssayEnabled: false,
  productType: '',
  scrollToOtherTests: () => noop(),
})

export default GeneDetailContext
