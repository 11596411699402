import * as React from 'react'
import classNames from 'classnames'

import {NvHeading} from '@invitae/ids-react'

import GeneDetailContext from 'components/GeneDetail/GeneDetailContext'
import SeeInvitaeTestsText from 'components/GeneDetail/SeeInvitaeTestsText'

import styles from './GeneralInfo.module.scss'

interface IGeneInfoProps {
  name: string
  assayNotes: string
  showTabs?: boolean
}

const GeneralInfo = ({name, assayNotes, showTabs}: IGeneInfoProps) => {
  const {isCustomAssayEnabled} = React.useContext(GeneDetailContext)

  const shouldShowAssayNotes = React.useMemo(() => !!assayNotes.length && !showTabs && !isCustomAssayEnabled, [])

  return (
    <div
      className={classNames(styles.generalInfo, {
        [styles.noTabs]: !showTabs,
      })}
    >
      <div className={styles.nameWrapper}>
        <NvHeading className={styles.geneName} element="h3" level={1}>
          {name}
        </NvHeading>
        {!showTabs && <SeeInvitaeTestsText />}
        {shouldShowAssayNotes && (
          <p className={styles.assayNotes} data-testid="assay-notes">
            {assayNotes}
          </p>
        )}
      </div>
    </div>
  )
}

export default GeneralInfo
