import * as React from 'react'
import classNames from 'classnames'

import {NvHeading} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'
import {GeneTestForPanelDetailedPageDto, OptTestForPanelDetailedPageDto} from '@invitae/stargate'

import {getAllGeneTests, getOptInRNAGenesSet} from 'components/PanelGeneDetail/panelGeneUtils'
import {TDetailsPageType} from 'constants/pageType'
import {getAnalyticsCurrentPageSection, parseHtml} from 'utils/utils'

import AssaysModal from './AssaysModal'

import styles from './AssaysSection.module.scss'

const SECTION_NAME = 'assays'

interface IAssaysSectionFields {
  geneTests?: GeneTestForPanelDetailedPageDto[]
  optTests?: OptTestForPanelDetailedPageDto[]
  assayInformation: string
  productType?: string
  isGene?: boolean
  pageType: TDetailsPageType
  shouldShowAssayInfo?: boolean
}

const AssaysSection = ({
  geneTests,
  optTests,
  assayInformation,
  productType,
  isGene,
  pageType,
  shouldShowAssayInfo = true,
}: IAssaysSectionFields) => {
  const {linkClickLogEvent} = useAnalyticsQueue()
  const [isAssaysModalOpened, setIsAssaysModalOpened] = React.useState(false)
  const [allGeneTests, setAllGeneTests] = React.useState([] as any[])
  const [optInRNAGenesSet, setOptInRNAGenesSet] = React.useState([] as string[])

  React.useEffect(() => {
    setAllGeneTests(getAllGeneTests(geneTests ?? [], optTests ?? []))
  }, [geneTests, optTests])

  React.useEffect(() => {
    setOptInRNAGenesSet(getOptInRNAGenesSet(productType ?? '', optTests ?? []))
  }, [productType, optTests])

  const handleShowMoreAnalyticsEvent = React.useCallback(() => {
    setIsAssaysModalOpened(true)
    linkClickLogEvent({
      currentPage: getAnalyticsCurrentPageSection(SECTION_NAME, pageType),
      eventProperties: {
        referrer: document.referrer,
      },
      linkName: 'show more link',
    })
  }, [linkClickLogEvent])

  if (!shouldShowAssayInfo || !assayInformation || (assayInformation && !assayInformation.length)) return null

  return (
    <>
      <div className={styles.assaysWrapper}>
        <NvHeading className={styles.assaysTitle} element="h4" level={3}>
          Assay information
        </NvHeading>
        <div className={styles.assaysText}>{parseHtml(assayInformation)}</div>
        <button
          className={classNames('nv-plain-action', styles.assaysLink)}
          data-testid="assay-show-more"
          onClick={handleShowMoreAnalyticsEvent}
        >
          Show more
        </button>
      </div>
      <AssaysModal
        allGeneTests={allGeneTests}
        assays={assayInformation}
        isGene={isGene ?? false}
        onClose={() => setIsAssaysModalOpened(false)}
        open={isAssaysModalOpened}
        optInRNAGenesSet={optInRNAGenesSet}
      />
    </>
  )
}

export default AssaysSection
