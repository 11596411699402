import * as React from 'react'

import {CircleVariant, ColorVariant, IconCheckmark, SizeVariant} from '@invitae/iconograph-react'

import PanelModal from 'components/PanelGeneDetail/PanelModal'
import {parseHtml} from 'utils/utils'

import styles from './AssaysSection.module.scss'
interface IAssayGeneTestsFields {
  name: string
  sequencingAnalysis: boolean
  transcriptReference: string
  deldupAnalysis: boolean
  assayNotes: string
}

interface IAssaysModalProps {
  open: boolean
  onClose: () => void
  assays: string
  optInRNAGenesSet: string[]
  allGeneTests?: IAssayGeneTestsFields[]
  isGene: boolean
}

const showGeneTests = (geneTests: IAssayGeneTestsFields[]): boolean => {
  return (
    geneTests.filter(gene => {
      return gene.transcriptReference !== '' || gene.sequencingAnalysis || gene.deldupAnalysis
    }).length > 0
  )
}

const AssaysModal = ({open, onClose, assays, allGeneTests, optInRNAGenesSet, isGene}: IAssaysModalProps) => {
  return (
    <PanelModal onClose={onClose} open={open} title="Assay information">
      <div className={styles.dynamicAssayContent} data-testid="dynamic-assay-content">
        {parseHtml(assays)}
      </div>
      {showGeneTests(allGeneTests ?? []) && (
        // string classnames are used to re-using styles
        // the same classnames are used for disorders tested modal
        <div className="gene-table-wrapper" data-testid="gene-table">
          <table className="gene-table table-alt-rows-color">
            <thead>
              <tr>
                <th>Gene</th>
                <th>Transcript reference</th>
                <th>Sequencing analysis</th>
                <th>Deletion/Duplication analysis</th>
                {!!optInRNAGenesSet.length && <th>RNA analysis</th>}
              </tr>
            </thead>
            <tbody>
              {allGeneTests!.map(item => (
                <tr key={item.name}>
                  <td>
                    {item.name}
                    {!!item.assayNotes && '*'}
                  </td>
                  <td>{item.transcriptReference}</td>
                  <td>
                    {item.sequencingAnalysis ? (
                      <div className={styles.tableCellIconWrapper}>
                        <IconCheckmark
                          circle={CircleVariant.no}
                          className={styles.assayCheckmark}
                          color={ColorVariant.none}
                          currentColor
                          size={SizeVariant.small}
                        />
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {item.deldupAnalysis ? (
                      <div className={styles.tableCellIconWrapper}>
                        <IconCheckmark
                          circle={CircleVariant.no}
                          className={styles.assayCheckmark}
                          color={ColorVariant.none}
                          currentColor
                          size={SizeVariant.small}
                        />
                      </div>
                    ) : null}
                  </td>
                  {!!optInRNAGenesSet.length && (
                    <td data-testid="rna-analysis">
                      {optInRNAGenesSet.indexOf(item.name) >= 0 ? (
                        <div className={styles.tableCellIconWrapper}>
                          <IconCheckmark
                            circle={CircleVariant.no}
                            className={styles.assayCheckmark}
                            color={ColorVariant.none}
                            currentColor
                            size={SizeVariant.small}
                          />
                        </div>
                      ) : null}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.assayNotes} data-testid="assay-notes">
            {allGeneTests!.map(item => (
              <p key={item.name}>
                {isGene && !!item.assayNotes && '*'}
                {item.assayNotes}
              </p>
            ))}
          </div>
        </div>
      )}
    </PanelModal>
  )
}

export default AssaysModal
