import * as React from 'react'
import classNames from 'classnames'

import {parseHtmlTextile} from 'utils/utils'

import styles from './AddonDescription.module.scss'

interface IAddonDescriptionProps {
  description: string
  isGeneCountEnabled: boolean
}

const AddonDescription = ({description, isGeneCountEnabled}: IAddonDescriptionProps) => {
  const [showMore, setShowMore] = React.useState(false)

  if (!description || (description && !description.length)) return null

  return (
    <div
      className={classNames(styles.addonTextWrapper, {
        [styles.paddingTop]: !isGeneCountEnabled,
      })}
    >
      {!showMore ? (
        <p className={styles.addonDescription}>{description}</p>
      ) : (
        <div data-testId="addon-full-text">{parseHtmlTextile(description)}</div>
      )}
      <button
        className={classNames('nv-plain-action', styles.addonLink, {
          [styles.showLessBtn]: showMore,
        })}
        data-testid="addon-show-more"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? 'Show less' : 'Show more'}
      </button>
    </div>
  )
}

export default AddonDescription
