import * as React from 'react'
import classNames from 'classnames'
import useResizeObserver from 'use-resize-observer'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {AddToCart} from '@invitae/add-to-cart'
import {ChevronGray, useAnalyticsQueue, useCountryLocationPrefix, useWindowDimensions} from '@invitae/nucleobase'

import {Authenticating} from 'constants/authState'
import {PROVIDERS_BILLING_LINK} from 'constants/links'
import {TDetailsPageType} from 'constants/pageType'

import styles from './OrderContainerWrapper.module.scss'

export interface IOrderContainerWrapperProps {
  isMobileOrderOpen: boolean
  setIsMobileOrderOpen: () => void
  children: React.ReactNode
  handleAddButtonClick: (onAddToCartSuccess?: () => void) => Promise<void | boolean>
  title: string
  showAdded: boolean
  pageType: TDetailsPageType
  shouldOpenModal?: boolean
  selectedGene?: string
}

const OrderContainerWrapper = ({
  isMobileOrderOpen,
  setIsMobileOrderOpen,
  children,
  handleAddButtonClick,
  title,
  shouldOpenModal = true,
  showAdded = false,
  pageType,
  selectedGene,
}: IOrderContainerWrapperProps) => {
  const {linkClickLogEvent} = useAnalyticsQueue()
  const {addCountryCodePrefix} = useCountryLocationPrefix()
  const {isLargeOrUp} = useWindowDimensions()
  const [showGradient, setShowGradient] = React.useState(false)
  const panelCustomizationRef = React.useRef<null | HTMLDivElement>(null)
  const {ref, height = 1} = useResizeObserver<HTMLDivElement>()
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const shouldHideMobileOrder = React.useMemo(() => !isMobileOrderOpen, [isMobileOrderOpen])
  const {authState} = React.useContext(UserAuthStatusContext)

  React.useEffect(() => {
    if (panelCustomizationRef.current) {
      setShowGradient(scrollPosition + panelCustomizationRef.current.offsetHeight < height)
    }
  }, [height, panelCustomizationRef.current])

  const handleClickAnalyticsEvent = React.useCallback(() => {
    linkClickLogEvent({
      currentPage: `${pageType} page`,
      eventProperties: {
        'Link destination': addCountryCodePrefix(PROVIDERS_BILLING_LINK),
        referrer: document.referrer,
      },
      linkName: 'find answers link',
    })
  }, [linkClickLogEvent, pageType])

  const handleAddToOrderClick = React.useCallback(() => {
    // shouldOpenModal is used in case, it's a gene page with multiple product types
    // always true for panel pages
    if (shouldOpenModal) {
      if (isLargeOrUp || isMobileOrderOpen) {
        handleAddButtonClick(setIsMobileOrderOpen)
      } else {
        setIsMobileOrderOpen()
      }
    } else handleAddButtonClick()
  }, [handleAddButtonClick, setIsMobileOrderOpen, isLargeOrUp, isMobileOrderOpen, shouldOpenModal])

  const addToOrderButtonText = React.useMemo(() => {
    let buttonText = 'Add to order'
    if (showAdded) {
      buttonText = 'Added'
    }
    return buttonText
  }, [showAdded])

  return (
    <div className={styles.root}>
      <div className={styles.geneTileWrapper}>
        <div
          className={classNames(styles.titleWrapper, {[styles.titleWrapperClosed]: shouldHideMobileOrder})}
          data-testid="title-wrapper"
          onClick={() => !isLargeOrUp && setIsMobileOrderOpen()}
          onKeyDown={e => (e.key === 'Enter' && !isLargeOrUp ? setIsMobileOrderOpen() : null)}
          role="button"
          tabIndex={0}
        >
          <div className={styles.backButton}>
            <ChevronGray />
          </div>
          <div className={styles.primaryPanelTitle}>{title}</div>
        </div>
        <div className={styles.notScrollableWrapper}>
          <div
            className={classNames(styles.geneCustomizationWrapper, {
              [styles.geneCustomizationWrapperClosed]: shouldHideMobileOrder,
            })}
            onScroll={(event: React.UIEvent<HTMLDivElement>): void => {
              setShowGradient(
                event.currentTarget.scrollTop + event.currentTarget.offsetHeight < event.currentTarget.scrollHeight,
              )
              setScrollPosition(event.currentTarget.scrollTop)
            }}
            ref={panelCustomizationRef}
          >
            <div
              className={classNames(styles.innerContainer, {[styles.containerClosedOrder]: shouldHideMobileOrder})}
              data-testid="inner-container"
              ref={ref}
            >
              {children}
            </div>
          </div>
          {showGradient && <div className={styles.sectionGradient} data-testid="section-gradient"></div>}
        </div>
        <div className={styles.addToOrderButtonWrapper}>
          <AddToCart
            className={styles.addToOrderButton}
            dataTestId="add-to-cart-button"
            disabled={showAdded || authState === Authenticating}
            onClick={() => !showAdded && handleAddToOrderClick()}
            productId={`${selectedGene}`}
          >
            {addToOrderButtonText}
          </AddToCart>
        </div>
        <div className={styles.questionsWrapper}>
          <p>Question about billing?&nbsp;</p>
          <a
            data-testid="find-answers-link"
            href={addCountryCodePrefix(PROVIDERS_BILLING_LINK)}
            onClick={handleClickAnalyticsEvent}
          >
            Find answers
          </a>
        </div>
      </div>
    </div>
  )
}

export default OrderContainerWrapper
