import * as React from 'react'
import classNames from 'classnames'
import CheckIcon from 'icons/CheckIcon'

import {CircleVariant, ColorVariant, IconLock, SizeVariant} from '@invitae/iconograph-react'
import {NvCheckbox} from '@invitae/ids-react'

import InfoTooltip from 'components//InfoTooltip'
import AddonDescription from 'components/PanelDetail/PanelCustomizationSection/AddonDescription'
import CustomizedPanelGenesCount from 'components/PanelDetail/PanelCustomizationSection/CustomizedPanelGenesCount/CustomizedPanelGenesCount'
import GeneTiles from 'components/PanelDetail/PanelCustomizationSection/GeneTiles/index'
import PanelDetailsContext from 'components/PanelDetail/PanelDetailContext'
import {
  getSelectedPanelGeneCountFromTotalUnselectedGeneList,
  getUnselectedPanelGeneCountFromTotalUnselectedGeneList,
} from 'components/PanelDetail/panelDetailUtils'
import {LONG_GENES, shouldDisplayZeroGenePanel} from 'constants/specialCases'
import {IPanelGene} from 'hooks/types'

import styles from './PanelItemCustomization.module.scss'

export interface IPanelCustomizationProps {
  isPrimary: boolean
  isRequired?: boolean
  genesList: IPanelGene[]
  panelTitle: string
  panelCode?: string
  isLocked?: boolean
  totalGenesCount?: number
  description?: string
}

const PANEL_CANNOT_BE_CUSTOMIZED = 'This panel cannot be customized.'

const PanelItemCustomization = ({
  isPrimary,
  isRequired = false,
  genesList: panelGeneList,
  panelTitle,
  panelCode,
  isLocked = false,
  totalGenesCount,
  description,
}: IPanelCustomizationProps) => {
  const {
    handleSelectedPanel,
    selectedPanels,
    unselectedGenes: allUnselectedGenes,
    isGeneCountEnabled,
  } = React.useContext(PanelDetailsContext)

  const testIdPanelItem = React.useMemo(() => (isPrimary ? 'primary-panel' : 'addon-panel'), [isPrimary])

  const unselectedGenesCount = React.useMemo(
    () => getUnselectedPanelGeneCountFromTotalUnselectedGeneList(allUnselectedGenes, panelGeneList),
    [allUnselectedGenes, panelGeneList],
  )
  const selectedGenesCount = React.useMemo(
    () => getSelectedPanelGeneCountFromTotalUnselectedGeneList(allUnselectedGenes, panelGeneList),
    [allUnselectedGenes, panelGeneList],
  )

  const isCheckboxChecked = React.useMemo(
    () => (panelCode ? selectedPanels.includes(panelCode) : false),
    [
      //its panel
      panelCode,
      selectedPanels,
    ],
  )

  const isAllGenesSelected = React.useMemo(
    () => selectedGenesCount === totalGenesCount,
    [totalGenesCount, selectedGenesCount],
  )

  const isAllGenesExcluded = React.useMemo(
    () => unselectedGenesCount === totalGenesCount,
    [unselectedGenesCount, totalGenesCount],
  )

  const handleAddOnCheckboxClick = React.useCallback(() => {
    panelCode && handleSelectedPanel(!isCheckboxChecked, panelCode)
  }, [handleSelectedPanel, panelCode, isCheckboxChecked])

  const geneListIncludesLongGenes = React.useMemo(
    () => panelGeneList.some(item => LONG_GENES.includes(item.name)),
    [panelGeneList],
  )

  if (panelGeneList.length === 0 && !isPrimary && !(panelCode && shouldDisplayZeroGenePanel(panelCode))) return null

  return (
    <div className={classNames(styles.root)} data-testid={testIdPanelItem}>
      <div
        className={classNames(styles.topWrapper, {
          [styles.rootSingleItemPadding]: !isGeneCountEnabled(panelTitle, isCheckboxChecked),
          [styles.topWrapperHeader]: isGeneCountEnabled(panelTitle, isCheckboxChecked),
        })}
      >
        <div className={classNames(styles.panelTitleWrapper)}>
          {isPrimary || isRequired ? (
            <>
              <CheckIcon className={styles.iconCheck} data-testid="check-icon" />
              <p className={styles.panelTitle}>{unselectedGenesCount > 0 ? 'Customized panel' : panelTitle}</p>
            </>
          ) : (
            <NvCheckbox
              checked={isCheckboxChecked}
              className={styles.panelTitle}
              id={panelTitle}
              label={isAllGenesSelected || isAllGenesExcluded ? panelTitle : 'Customized panel'}
              onChange={handleAddOnCheckboxClick}
            />
          )}
          {isRequired && isLocked && (
            <div className={styles.requiredTextWrapper}>
              <IconLock
                circle={CircleVariant.no}
                className={styles.iconLock}
                color={ColorVariant.none}
                currentColor
                data-testid="lock-icon"
                size={SizeVariant.small}
              />
              <div className={styles.requiredText}>Required</div>
              <InfoTooltip tooltipText={PANEL_CANNOT_BE_CUSTOMIZED} />
            </div>
          )}
        </div>
        {isGeneCountEnabled(panelTitle) && (
          <CustomizedPanelGenesCount
            genesList={panelGeneList}
            isPanelSelected={isCheckboxChecked}
            isPrimaryOrRequired={isPrimary || isRequired}
          />
        )}
      </div>
      <AddonDescription description={description ?? ''} isGeneCountEnabled={isGeneCountEnabled(panelTitle)} />
      <div className={styles.divider}></div>
      {(isPrimary || isCheckboxChecked) && isGeneCountEnabled(panelTitle) && (
        <>
          <GeneTiles
            enableWideTiles={geneListIncludesLongGenes}
            geneTests={panelGeneList}
            isLocked={isLocked}
            isPrimary={isPrimary}
            isRequired={isRequired}
            panelCode={panelCode ?? ''}
          />
        </>
      )}
    </div>
  )
}

export default PanelItemCustomization
