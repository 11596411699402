import * as React from 'react'
import classNames from 'classnames'

import PanelDetailsContext from 'components/PanelDetail/PanelDetailContext'
import {
  getSelectedPanelGeneCountFromTotalUnselectedGeneList,
  getUnselectedPanelGeneCountFromTotalUnselectedGeneList,
} from 'components/PanelDetail/panelDetailUtils'
import {IPanelGene} from 'hooks/types'

import styles from './CustomizedPanelGenesCount.module.scss'
export interface ICustomizedPanelGenesCount {
  genesList: IPanelGene[]
  isPrimaryOrRequired: boolean
  isPanelSelected?: boolean
  disableGeneNaming?: boolean
}

const CustomizedPanelGenesCount = ({
  genesList: totalPanelGeneList,
  isPrimaryOrRequired,
  isPanelSelected = false,
  disableGeneNaming = false,
}: ICustomizedPanelGenesCount) => {
  const {unselectedGenes: totalUnselectedGeneList} = React.useContext(PanelDetailsContext)

  const unselectedGenesCount = React.useMemo(
    () => getUnselectedPanelGeneCountFromTotalUnselectedGeneList(totalUnselectedGeneList, totalPanelGeneList),
    [totalUnselectedGeneList, totalPanelGeneList],
  )
  const selectedGenesCount = React.useMemo(
    () => getSelectedPanelGeneCountFromTotalUnselectedGeneList(totalUnselectedGeneList, totalPanelGeneList),
    [totalUnselectedGeneList, totalPanelGeneList],
  )

  const isAtLeastOneGeneSelected = React.useMemo(() => selectedGenesCount > 0, [selectedGenesCount])

  const getCorrectGeneCounterTitle = (isPlural: boolean) => (isPlural ? 'genes' : 'gene')

  if (!isPrimaryOrRequired && !isPanelSelected && disableGeneNaming) return null

  // primary or required panel is checked by default so we always need to show the selected state for primary or required panel
  if (isPrimaryOrRequired || isPanelSelected) {
    return (
      <div
        className={classNames(styles.genesInfo, styles.openedGeneTiles, {
          [styles.primaryPanelGenesInfo]: isPrimaryOrRequired,
        })}
        data-testid="panel-genes-count-expanded"
        id="panelGenesCountExpanded"
      >
        {isAtLeastOneGeneSelected &&
          `${selectedGenesCount} ${
            !disableGeneNaming ? getCorrectGeneCounterTitle(selectedGenesCount > 1) : ''
          } selected`}
        {isAtLeastOneGeneSelected && !!unselectedGenesCount && `, `}
        {!!unselectedGenesCount &&
          `${unselectedGenesCount} ${
            !disableGeneNaming ? getCorrectGeneCounterTitle(unselectedGenesCount > 1) : ''
          } excluded`}
      </div>
    )
  }

  //state for unchecked optional panel gene count
  return (
    <div
      className={classNames(styles.genesInfo, styles.collapsedGeneTiles)}
      data-testid="panel-genes-count-default"
      id="panelGenesCountDefault"
    >
      {selectedGenesCount} {selectedGenesCount > 1 ? 'genes' : 'gene'}
    </div>
  )
}

export default CustomizedPanelGenesCount
