import React from 'react'

import {NvTab, useTabs} from '@invitae/ids-react'
import {GeneV2Dto} from '@invitae/stargate'

import GeneDetailContent from './GeneDetailContent'

interface GeneTabContentProps {
  genes: GeneV2Dto[]
  showTabs: boolean
  selectedGene: GeneV2Dto
}

export const GeneTabContent = ({genes, showTabs, selectedGene}: GeneTabContentProps) => {
  const {isCurrentTab} = useTabs()

  return (
    <>
      {genes.map(gene => (
        <NvTab.Panel key={gene.productType} show={isCurrentTab(gene.productType || '')} tabId={gene.productType || ''}>
          <GeneDetailContent gene={selectedGene} showTabs={showTabs} />
        </NvTab.Panel>
      ))}
    </>
  )
}
