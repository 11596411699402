import * as React from 'react'

import {BaseNucleobaseComponent, BaseNucleobaseComponentProps} from '@invitae/nucleobase'

import BaseTestCatalogContext from './BaseTestCatalogContext'

const BaseTestCatalogComponent = ({source, ...props}: BaseNucleobaseComponentProps) => {
  return (
    <BaseTestCatalogContext>
      <BaseNucleobaseComponent source={source} {...props} />
    </BaseTestCatalogContext>
  )
}

export default BaseTestCatalogComponent
