import * as React from 'react'
import classNames from 'classnames'
import {PanelCardsItemIndexContext} from 'wrapper/PanelCardsContext'

import {BaseSection} from '@invitae/nucleobase'

import PanelCardsSection from 'components/PanelCardsSection'
import {getAlternativeTestsIdsAndSubtext} from 'components/PanelDetail/panelDetailUtils'
import OtherTestsItemWrapper from 'components/PanelGeneDetail/OtherTestsItemWrapper/OtherTestsItemWrapper'
import {GREY_BACKGROUND} from 'constants/theme'
import {parseHtml} from 'utils/utils'

import styles from './OtherTestsSection.module.scss'

const OtherTestsSection = ({alternativeTests}: {alternativeTests: string}) => {
  const [items, setItems] = React.useState(
    [] as {
      subtext: string
      alternativeTests: React.ReactNode[]
      categories: string[]
    }[],
  )

  React.useEffect(() => {
    if (alternativeTests && alternativeTests.length) {
      const alternativeTestsArray = getAlternativeTestsIdsAndSubtext(alternativeTests)

      setItems(
        alternativeTestsArray.map(item => ({
          alternativeTests: item.alternativeTests.map((item: string, index: number) => (
            <PanelCardsItemIndexContext.Provider key={`PanelCardItem_${item}_${index}`} value={index + 1}>
              <OtherTestsItemWrapper mainCode={item} />
            </PanelCardsItemIndexContext.Provider>
          )),
          categories: item.categories,
          subtext: item.subtext,
        })),
      )
    }
  }, [alternativeTests])

  if (!alternativeTests || !alternativeTests.length || items.length <= 0) return null

  return (
    <div className={styles.testsWrapper}>
      {items.map((item, index) => (
        <>
          <PanelCardsSection
            customRootClassname={index > 0 ? 'no-padding' : ''}
            id={`alternative-tests-${index}`}
            internalName="Other tests"
            items={item.alternativeTests}
            key={`alternative-tests-${index}`}
            showBreadcrumbs={false}
            subHeading={index === 0 ? 'Other tests to consider' : undefined}
            subtext={item.subtext}
            theme={GREY_BACKGROUND}
          />
          {item.categories.length > 0 && (
            <BaseSection
              className={classNames('nv-container', styles.alternativeCategories)}
              id="alternative-categories"
            >
              {item.categories.map(el => parseHtml(el))}
            </BaseSection>
          )}
        </>
      ))}
    </div>
  )
}

export default OtherTestsSection
