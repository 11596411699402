import * as React from 'react'
import CheckIcon from 'icons/CheckIcon'

import {NvRadioButton} from '@invitae/ids-react'

import styles from './GeneItem.module.scss'

export interface IGeneItemProps {
  isSingleGene?: boolean
  title: string
  selected: boolean
  onClick: () => void
}

const GeneItem = ({isSingleGene = false, title, selected, onClick}: IGeneItemProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.topWrapper}>
        <div className={styles.geneTitleWrapper}>
          {isSingleGene ? (
            <>
              <CheckIcon data-testid="check-icon" />
              <p className={styles.geneTitle}>{title}</p>
            </>
          ) : (
            <NvRadioButton className={styles.radioTitle} label={title} onClick={onClick} selected={selected} />
          )}
        </div>
      </div>
    </div>
  )
}

export default GeneItem
