import * as React from 'react'

import {useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'

import {SponsoredTestingType} from 'api/types'
import {getShouldShowFastTurnaroundTag} from 'components/CategoryTabSection/PanelTab/PanelItem/panelItemsUtils'
import IconClock from 'components/PanelTagTooltip/PanelTagTAT/IconClock'
import {DISABLED_SPONSORED_SECTION_CLEAN_CODE} from 'constants/specialCases'
import {getCleanCode} from 'utils/utils'

import SponsoredIcon from './SponsoredIcon'

import styles from './OrderingTagsSection.module.scss'

interface IPanelInfoProps {
  turnaroundTime: string
  partnerships: SponsoredTestingType
  code?: string
}

const OrderingTagsSection = ({turnaroundTime, partnerships, code}: IPanelInfoProps) => {
  const partnershipsList = React.useMemo(
    () => (code ? partnerships[code.split('.')[0]] ?? [] : []),
    [partnerships, code],
  )

  const shouldShowFastTurnaroundTag = React.useMemo(
    () => getShouldShowFastTurnaroundTag(turnaroundTime),
    [turnaroundTime],
  )

  //temporary disable sponsored testing section on Invitae Arrhythmia and Cardiomyopathy Comprehensive Panel
  //https://invitae.jira.com/browse/CEPN-277
  const isCodeDisabledForSponsoredSection = getCleanCode(code || '') === DISABLED_SPONSORED_SECTION_CLEAN_CODE

  const shouldShowPartnershipsTag = partnershipsList.length > 0 && !isCodeDisabledForSponsoredSection

  const {logEvent} = useAnalyticsQueue()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const handleOpenSponsoredTestingLinkAnalyticsEvent = React.useCallback(
    (linkDestination: string) => {
      logEvent({
        eventName: 'click sponsored testing link on panel page',
        eventProperties: {
          'Current page': window.location.href,
          'Link destination': addCountryCodePrefix(linkDestination),
          referrer: document.referrer,
        },
      })
    },
    [logEvent],
  )

  if (!shouldShowFastTurnaroundTag && !shouldShowPartnershipsTag) return null

  return (
    <div className={styles.orderingTagsWrapper}>
      {shouldShowPartnershipsTag && (
        <div className={styles.orderingTagWrapper}>
          <SponsoredIcon />
          <div className={styles.textPartnershipWrapper}>
            <div className={styles.orderingTagsText}>
              <p className={styles.tagTitle}>Sponsored testing</p>
              <p className={styles.tagDescription}>
                In addition to insurance and patient-pay billing options, this test is also available through a
                sponsored, no-charge testing program.
              </p>
            </div>
            {partnershipsList?.length > 0 && (
              <div className={styles.partnershipWrapper}>
                {partnershipsList.map(item => (
                  <a
                    className={styles.partnershipLink}
                    href={item.landingPageUrl ?? ''}
                    key={item.id}
                    onClick={() => handleOpenSponsoredTestingLinkAnalyticsEvent(item.landingPageUrl ?? '')}
                    rel="noreferrer"
                  >
                    {item.displayName}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {shouldShowFastTurnaroundTag && (
        <div className={styles.orderingTagWrapper}>
          <IconClock fill="black" />
          <div className={styles.orderingTagsText}>
            <p className={styles.tagTitle}>Fast turnaround</p>
            <p className={styles.tagDescription}>This test has a faster turnaround time than most Invitae panels.</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrderingTagsSection
